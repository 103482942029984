'use client';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { FormState } from 'react-hook-form';
import { usePrevious } from 'react-use';

const warningText = 'You have unsaved changes - are you sure you wish to leave this page?';

const PreventNavigationAwayContext = createContext<{
  isLive: boolean;
  setIsLive: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export const PreventNavigationAwayProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLive, setIsLive] = useState(false);
  const pathname = usePathname();
  const router = useRouter();
  const prevPathname = usePrevious(pathname);

  const value = useMemo(
    () => ({
      isLive,
      setIsLive,
    }),
    [isLive, setIsLive]
  );

  useEffect(() => {
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (!isLive) {
        return;
      }

      e.preventDefault();

      return (e.returnValue = warningText);
    };

    window.addEventListener('beforeunload', handleWindowClose);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [isLive]);

  useEffect(() => {
    if (isLive && prevPathname && prevPathname !== pathname) {
      if (window.confirm(warningText)) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      return router.replace(prevPathname);
    }
  }, [isLive, pathname, prevPathname, router]);

  return (
    <PreventNavigationAwayContext.Provider value={value}>
      {children}
    </PreventNavigationAwayContext.Provider>
  );
};

function PreventNavigationAway({ formState }: { formState: FormState<any> }) {
  const context = useContext(PreventNavigationAwayContext);

  useEffect(() => {
    if (!formState.isDirty || formState.isSubmitting || formState.isSubmitSuccessful) {
      context?.setIsLive(false);
      return;
    }

    context?.setIsLive(true);
  }, [context, formState]);

  return null;
}

export default PreventNavigationAway;
