'use client';
import {
  Badge,
  Card,
  Fieldset,
  MantineProvider,
  MantineThemeOverride,
  Table,
  TableTd,
} from '@mantine/core';
import React, { ReactNode } from 'react';
import { useApollo } from '../apolloClient';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { Notifications } from '@mantine/notifications';
import ConfettiProvider from '../components/Misc/ConfettiProvider';
import NewVersionDeployed from '../components/Misc/NewVersionDeployed';
import LogRocketRun from '../components/Misc/LogRocketRun';
import { PreventNavigationAwayProvider } from '../components/PreventNavigationAway';

const theme: MantineThemeOverride = {
  primaryColor: 'dark',
  fontFamily:
    'Inter,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,Arial, Noto Sans',
  fontSizes: {
    sm: '13px',
    md: '14px',
  },
  components: {
    Card: Card.extend({
      styles: {
        root: {
          overflow: 'initial',
        },
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        variant: 'light',
      },
    }),
    TableTd: TableTd.extend({
      defaultProps: {
        fz: 14,
      },
    }),
    Table: Table.extend({
      defaultProps: { verticalSpacing: 'md', highlightOnHover: false },
    }),
    Fieldset: Fieldset.extend({
      defaultProps: {
        variant: 'unstyled',
      },
    }),
  },
};

function ShepherdProvider({ children }: { children: ReactNode }) {
  const apolloClient = useApollo(undefined);

  return (
    <CookiesProvider>
      <ApolloProvider client={apolloClient}>
        <MantineProvider
          theme={{
            ...theme,
          }}
          forceColorScheme="light"
        >
          <Notifications />
          <ConfettiProvider>
            <LogRocketRun />
            <PreventNavigationAwayProvider>{children}</PreventNavigationAwayProvider>
            <NewVersionDeployed />
          </ConfettiProvider>
        </MantineProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
}

export default ShepherdProvider;
