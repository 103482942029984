import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';
import setupLogRocketReact from 'logrocket-react';
import { useEffect } from 'react';
import { IS_PRODUCTION } from '../../constants';

const LogRocketRun = () => {
  useEffect(() => {
    if (IS_PRODUCTION) {
      LogRocket.init('s2m1ay/shepherd', {
        release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      });
      setupLogRocketReact(LogRocket);

      LogRocket.getSessionURL((sessionURL) => {
        Sentry.withScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  }, []);

  return null;
};

export default LogRocketRun;
